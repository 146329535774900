<template>
  <div class="register-page">
    <div class="section has-background-primary has-text-white">
      <div class="container is-tablet p-t-10x">
        <h1 class="title is-size-3 m-b  has-text-white has-text-centered">
          {{ $t('views.customer.CheckEmail.title') }}
        </h1>
      </div>
    </div>
    <div class="section">
      <div class="container is-tablet p-t-4x has-text-centered">
        <p class="m-b-6x">
          <IconBase
            width="80"
            height="80"
            icon-name="email">
            <IconEmail />
          </IconBase>
        </p>
        <p
          class="m-b-4x"
          v-html="$t('views.customer.CheckEmail.message',{ email: $route.query.email })" />
      </div>
    </div>
  </div>
</template>

<script>
import IconEmail from '../../components/icons/IconEmail'

export default {
  name: 'CheckEmail',
  components: {
    IconEmail
  },
  metaInfo () {
    return {
      title: 'Reset Email Sent'
    }
  }
}
</script>

<style scoped>

</style>
