<template>
  <div class="register-page">
    <div class="section has-background-primary has-text-white">
      <div class="container is-tablet p-t-10x has-text-centered">
        <h1 class="title is-size-3 m-b  has-text-white">
          {{ $t('views.customer.ResetPassword.title') }}
        </h1>
        <p>{{ $t('views.customer.ResetPassword.desc',{email: $route.query.email}) }}</p>
      </div>
    </div>
    <div class="section">
      <div class="container is-mobile p-t-4x">
        <el-form
          ref="localForm"
          class="columns is-multiline"
          :model="localForm"
          :rules="rules"
          label-position="top"
          status-icon
          @submit.native="handleSubmit">
          <el-form-item
            class="column is-12"
            label="Password"
            prop="password">
            <template #label>
              {{ $t('views.customer.form.password.label') }}
              <a
                class="toggle-password has-text-secondary is-pulled-right"
                @click="togglePassword">{{ togglePasswordText }}</a>
            </template>
            <el-input
              v-model.trim="localForm.password"
              :type="passwordType"
              autocomplete="off"
              @input="handleValidRetype" />
          </el-form-item>
          <el-form-item
            class="column is-12"
            :label="$t('views.customer.form.retype-password.label')"
            prop="retypePassword">
            <el-input
              v-model.trim="localForm.retypePassword"
              :type="passwordType"
              autocomplete="off" />
          </el-form-item>
          <div class="column is-12 recaptcha-column">
            <Recaptcha
              ref="recaptcha"
              @verify="handleRecaptchaVerify"
              @error="handleRecaptchaError" />
          </div>
          <div
            v-if="error"
            class="column is-12">
            <el-alert
              :title="error"
              :closable="false"
              type="error" />
          </div>
          <div class="column is-12">
            <el-button
              class="button-secondary m-r-3x"
              style="min-width: 107px;"
              :loading="isSubmitting"
              native-type="submit">
              {{ $t('views.customer.form.save') }}
            </el-button>
          </div>
          <div class="column is-12">
            <RecaptchaTerms />
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import mixPassword from './mixins/password'
import Recaptcha from '@/components/Recaptcha'
import RecaptchaTerms from '@/components/RecaptchaTerms'

export default {
  name: 'ResetPassword',
  components: { RecaptchaTerms, Recaptcha },
  mixins: [mixPassword],
  data () {
    return {
      isSubmitting: false,
      error: '',
      localForm: {
        password: undefined,
        retypePassword: undefined
      },
      rules: {
        password: [
          {
            min: 6,
            message: this.$t('views.customer.form.password.invalid'),
            trigger: [
              'change', 'blur'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.password.required'),
            trigger: [
              'change', 'blur'
            ]
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
            message: this.$t('views.customer.form.password.regex'),
            trigger: ['change', 'blur']
          }
        ],
        retypePassword: [
          {
            min: 6,
            message: this.$t('views.customer.form.password.invalid'),
            trigger: [
              'change', 'blur'
            ]
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.localForm.password) {
                callback(new Error(this.$t('views.customer.form.retype-password.invalid')))
              } else {
                callback()
              }
            },
            trigger: [
              'change', 'blur'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.retype-password.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      }
    }
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.error = ''
      this.$refs.localForm.validate((valid) => {
        if (valid) {
          this.isSubmitting = true
          this.$refs.recaptcha.execute()
        }
      })
    },
    handleValidRetype () {
      if (this.localForm.retypePassword !== undefined) {
        this.$refs.localForm.validateField('retypePassword')
      }
    },
    handleRecaptchaVerify (token) {
      const payload = {
        password: this.localForm.password,
        token: this.$route.query.actionToken
      }
      this.$store.dispatch('user/resetPassword', {
        data: payload,
        headers: {
          'x-recaptcha-token': token,
          'x-recaptcha-ver': 'V2_INVISIBLE'
        }
      }).then(
        (_) => {
          this.isSubmitting = false
          this.$refs.recaptcha.reset()
          this.$router.push({ name: 'reset-password-success' })
        }
      ).catch((err) => {
        this.isSubmitting = false
        this.$refs.recaptcha.reset()
        this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
          show: true,
          content: err.message || this.$t(`error.resetPassword.${err.error.code}`) || (err.error && err.error.message) || ''
        })
      })
    },
    handleRecaptchaError (message) {
      this.error = message
      this.isSubmitting = false
      this.$refs.recaptcha.reset()
    }
  },
  metaInfo () {
    return {
      title: 'Create New Password'
    }
  }
}
</script>

<style scoped>

</style>
